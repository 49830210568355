<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import StatusBadge from "../../../components/elements/status-badge.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        StatusBadge,
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            'loading': 'project/loading',
            'states': 'project/states',
            'errors': 'project/errors',
            users: 'user/all',
            user: 'user/authenticated',
            statuses: 'status/all'
        }),

        filteredUsers: function () {
            return this.users.filter(user => user.id !== this.user?.id)
        },

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('projects::projects.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "target_type",
                    label: this.$tc('partners::partners.partner').ucFirst(),
                    formatter: (value, index, item) => item.target ? (item.target.translation_key ? this.$t(item.target.translation_key).ucFirst() : item.target.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "project_type_id",
                    label: this.$t('projects::projects.relationships.project_type').ucFirst(),
                    formatter: (value, index, item) => item.project_type ? (item.project_type.translation_key ? this.$t(item.project_type.translation_key).ucFirst() : item.project_type.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "process_id",
                    label: this.$t('projects::projects.relationships.process').ucFirst(),
                    formatter: (value, index, item) => item.process ? (item.process.translation_key ? this.$t(item.process.translation_key).ucFirst() : item.process.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "step_id",
                    label: this.$t('projects::projects.relationships.step').ucFirst(),
                    formatter: (value, index, item) => item.step ? (item.step.translation_key ? this.$t(item.step.translation_key).ucFirst() : item.step.name) : value,
                    thClass: '',
                    sortable: true
                },
                /*{
                    key: "user",
                    label: this.$t('projects::projects.relationships.user').ucFirst(),
                    formatter: (value) => value.translation_key ? this.$t(value.translation_key).ucFirst() : value.name,
                    thClass: '',
                    sortable: true
                },*/
                {
                    key: "status_id",
                    label: this.$t('projects::projects.relationships.status').ucFirst(),
                    formatter: (value, index, item) => item.status ? (item.status.translation_key ? this.$t(item.status.translation_key).ucFirst() : item.status.name) : value,
                    filterable: 'status',
                    thClass: '',
                    sortable: true
                },
                {
                    key: "users",
                    label: this.$t('projects::projects.relationships.users').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "followers",
                    label: this.$t('projects::projects.relationships.followers').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "contributors",
                    label: this.$t('projects::projects.relationships.contributors').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {
            modal: false,
            model: {
                user_type: 'App\\Models\\User'
            },
            temp: null,
            callback: null,
            filters: { status: null }
        };
    },

    methods: {
        transfer: function () {
            this.$store.dispatch('project/transfer', {id: this.temp?.id, data: this.model}).then(() => {
                this.closeModal()
            })
        },

        closeModal: function () {
            this.model.user_id = null
            this.modal = false
            this.temp = null
            this.callback = null
        },

        openModal: function (item, fetch) {
            this.modal = true
            this.temp = item
            this.callback = fetch
        },

        fetchUsers: function () {
            this.$store.dispatch('user/all')
        },

        fetchStatuses: function () {
            this.$store.dispatch('status/all')
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'projects::projects.project', count: 2})
        this.fetchUsers()
        this.fetchStatuses()
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <div>
        <advanced-table
            :caption="$tc('projects::projects.project', 2).ucFirst()"
            :create-link="{ name: 'my-project-create' }"
            :current-page="1"
            :edit-link="{ name: 'my-project-edit' }"
            :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
            :empty-text="$t('base.empty_table').ucFirst()"
            :fields="fields"
            :queries="{ with: ['target', 'projectType', 'process', 'step', 'user', 'status', 'followers', 'contributors', 'users'] }"
            :per-page="10"
            borderless
            deletable
            editable
            hover
            action="own"
            moduleNamespace="project"
            module-pager="ownPager"
            outlined
            permission="projects"
            primary-key="id"
            responsive="sm"
            searchable
            show-empty
            sort-by="id"
            sort-direction="asc"
            sort-icon-left
            archive
        >
            <template #filters>
                <div>
                    <select
                        id="filter-status"
                        v-model="filters.status"
                        class="form-select"
                        name="filter-status"
                        style="max-width: 200px;"
                    >
                        <option :value="null">
                            {{ $t('projects::projects.placeholders.status_id').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in statuses" :key="index" :value="item.id">
                            {{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}
                        </option>
                    </select>
                </div>
            </template>
            <template #users="{value}">
                <div class="avatar-group">
                    <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                        <a v-if="user.image && user.image.path" href="javascript: void(0);" class="d-inline-block">
                            <img :src="user.image.path"
                                 :alt="user.name"
                                 :title="user.name"
                                 class="rounded-circle avatar-sm">
                        </a>
                        <div v-else class="avatar-sm cursor-pointer">
                        <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                    </div>
                    </div>
                </div>
            </template>
            <template #contributors="{value}">
                <div class="avatar-group">
                    <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                        <a v-if="user.image && user.image.path" href="javascript: void(0);" class="d-inline-block">
                            <img :src="user.image.path"
                                 :alt="user.name"
                                 :title="user.name"
                                 class="rounded-circle avatar-sm">
                        </a>
                        <div v-else class="avatar-sm cursor-pointer">
                            <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #followers="{value}">
                <div class="avatar-group">
                    <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                        <a v-if="user.image && user.image.path" href="javascript: void(0);" class="d-inline-block">
                            <img :src="user.image.path"
                                 :alt="user.name"
                                 :title="user.name"
                                 class="rounded-circle avatar-sm">
                        </a>
                        <div v-else class="avatar-sm cursor-pointer">
                            <span :title="user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.name.firstLetters() }} </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #status_id="{item, formatted}">
                <status-badge :bg-color="item?.status?.color">{{ formatted }}</status-badge>
            </template>
            <template #more-action="{ item, fetch }">
                <li class="list-inline-item">
                    <a
                        v-if="can('projects.update')"
                        @click.prevent="openModal(item, fetch)"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="$t('base.add_to_another_user').ucFirst()"
                        class="px-2 text-dark cursor-pointer"
                    ><i class="bx bx bx-transfer font-size-18"></i
                    ></a>
                </li>
            </template>
        </advanced-table>
        <b-modal v-model="modal" hide-header>
            <b-overlay v-model="loading">
                <b-form @submit.prevent>
                    <div class="col-12">
                        <label
                            :class="{'text-danger': states.user_id === false}"
                            class="form-label"
                            for="user_id"
                        >{{ $t('projects::projects.columns.user_id').ucFirst() }}</label>
                        <select
                            id="user_id"
                            v-model="model.user_id"
                            :class="{'border-danger': states.user_id === false}"
                            class="form-select"
                            name="user_id"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('projects::projects.placeholders.user_id').ucFirst() }}
                            </option>
                            <option v-for="(user, key) in filteredUsers" :key="key" :value="user.id">{{
                                    user.name
                                }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">
                            {{ errors.user_id }}
                        </div>
                    </div>
                </b-form>
            </b-overlay>
            <template #footer="">
                <b-button variant="secondary" @click="closeModal">{{ $t('base.cancel').ucFirst() }}</b-button>
                <b-button variant="primary" @click="transfer">{{ $t('base.do_it').ucFirst() }}</b-button>
            </template>
        </b-modal>
    </div>
</template>
