<script>
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        bgColor: {
            type: String,
            default: 'transparent'
        }
    },

    computed: {
        textColor: function () {
            if (this.bgColor.charAt(0) === '#' && this.bgColor.length === 7) {
                const r = this.bgColor.substring(1, 3),
                    g = this.bgColor.substring(4, 5),
                    b = this.bgColor.substring(6, 7);

                return (r*0.299 + g*0.587 + b*0.114) > 186 ? '#000000' : '#ffffff'
            }

            return '#000000'
        },
    }
})
</script>

<template>
    <span class="badge" :style="{background: bgColor, color: textColor}">
        <slot></slot>
    </span>
</template>

<style scoped>

</style>
